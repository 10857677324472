import { stylesheet } from "typestyle";

const webOne = (color) =>
  stylesheet({
    imageRatio: {
      aspectRatio: "16/9",
      width: "100%",
      objectFit: "contain",
    },
    header: {
      position: "absolute",
      left: "0",
      right: "0",
      top: "0",
      zIndex: "100",
      background: color.primary,
    },
    navbar: {
      position: "relative",
      left: "0",
      right: "0",
      top: "0",
      zIndex: "100",
      background: "#FFF",
      height: "60px",
      width: "100%",
      borderBottom: "4px solid" + color.secondary,
    },
    logo: {
      minWidth: "90px",
      maxWidth: "100px",
      width: "100%",
    },
    longLogo: {
      maxWidth: "200px",
      width: "100%",
    },
    logoContainer: {
      display: "inline-block",
      maxHeight: "100px",
      paddingTop: "5px",
    },
    navList: {
      margin: "0",
      listStyle: "none",
      float: "left",
    },
    navListItem: {
      width: "auto !important",
      padding: "15px 10px 25px !important",
      float: "left",
      fontSize: "16px",
      textTransform: "uppercase",
      "@media (max-width: 991px)": {
        width: "100% !important",
      },
    },
    navItemLink: {
      textDecoration: "none",
      fontSize: "0.9em",
      color: "#222",
      "@media screen and (max-width: 1100px)": {
        fontSize: "0.8em",
      },
      "&:hover": {
        color: color.primary,
        textDecoration: "none",
      },
      "@media screen and (max-width: 990px)": {
        fontSize: "1em",
        color: "white !important",
      },
    },
    navSubmenu: {
      position: "absolute !important",
      top: "0",
      background: "#FFF",
      minWidth: "5rem",
      whiteSpace: "nowrap",
      transition: "300ms linear",
      transitionProperty: "opacity, visibility",
      transitionDelay: "150ms",
      boxShadow: "0 10px 0.8125rem 0 rgba(2, 3, 3, 0.24)",
      "&:hover": {
        cursor: "pointer",
      },
    },
    navSubmenuTitle: {
      display: "block",
      width: "auto !important",
      float: "left",
      textTransform: "uppercase",
      color: "#222",
      "&:hover": {
        color: color.primary,
        textDecoration: "none",
        cursor: "pointer",
        "&>*:first-child": {
          visibility: "visible",
          opacity: "1",
          display: "block",
        },
      },
    },
    navSubmenuItem: {
      width: "100% !important",
      float: "left",
      fontSize: "0.85em",
      textTransform: "uppercase",
      textDecoration: "none",
      borderBottom: "2px solid " + color.primary,
      "&:hover": {
        textDecoration: "none",
      },
    },
    navSubmenuLink: {
      textDecoration: "none",
      color: "#222",
      "&:hover": {
        color: color.primary,
        backgroundColor: "#C0C0C0",
        textDecoration: "none",
      },
    },
    navThirdLevelSubmenu: {
      position: "absolute !important",
      background: "#FFF",
      minWidth: "5rem",
      left: "225px !important",
      whiteSpace: "nowrap",
      transition: "300ms linear",
      transitionProperty: "opacity, visibility",
      transitionDelay: "150ms",
      boxShadow: "0 10px 0.8125rem 0 rgba(2, 3, 3, 0.24)",
      "&:hover": {
        cursor: "pointer",
      },
    },
    topBar: {
      position: "relative",
      //borderBottom: '5px solid #b1a38c',
      background: "#FFF",
      height: "auto",
      lineHeight: "0px",
      width: "100%",
    },
    topLinks: {
      float: "left",
      textAlign: "left",
      "@media (max-width: 1048px)": {
        textAlign: "center",
      },
    },
    topLinksList: {
      margin: "0 !important",
      padding: "0 !important",
      listStyle: "none",
      width: "auto",
    },
    topLinksItem: {
      height: "40px",
      width: "auto !important",
      padding: "10px 5px !important",
      display: "inline-block !important",
      ":firstChild": {
        borderLeft: "0 !important",
      },
      "&:hover": {
        //backgroundColor: color.primary
      },
    },
    topLinksButton: {
      width: "auto !important",
      display: "inline-block !important",
      padding: "10px 5px !important",
      ":firstChild": {
        borderLeft: "0 !important",
      },
      "@media (max-width: 768px)": {
        padding: "5px !important",
      },
    },
    topLink: {
      display: "block",
      padding: "10px 5px",
      textTransform: "uppercase",
      height: "30px",
      color: "#222",
      textDecoration: "none",
      fontSize: "13px",
      "&:hover": {
        color: color.primary,
        textDecoration: "none",
      },
    },
    topSocial: {
      margin: "0 ",
      padding: "0",
      listStyle: "none",
      width: "auto",
      textAlign: "center",
    },
    topSocialList: {
      float: "right",
      padding: "0px !important",
      "@media (max-width: 1048px)": {
        float: "none !important",
      },
    },
    topSocialListItem: {
      display: "inline-block !important",
      padding: "10px 0px !important",
      position: "relative",
      float: "left",
      width: "auto !important",
      height: "40px",
      "@media (max-width: 1048px)": {
        float: "none",
      },
    },
    topSocialLink: {
      float: "none",
      width: "30px",
      fontWeight: "bold",
      color: "#000",
      display: "inline-block",
      "&:hover": {
        color: color.primary,
        textDecoration: "none",
      },
      "@media (max-width: 768px)": {},
    },
    slider: {
      position: "relative",
      height: "auto",
      width: "auto",
      "@media (max-width: 600px)": {
        height: "125%",
      },
    },
    innerContainer: {
      width: "100%",
      height: "100%",
      margin: "0",
      position: "relative",
    },
    slide: {
      textAlign: "center",
      width: "100%",
      background: "#000",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    images: {
      width: "100%",
    },
    featureBar: {
      height: "auto",
      backgroundColor: "#508055",
      borderBottom: "5px solid #B18F47",
    },
    feature: {
      margin: "0 auto !important",
      display: "inline-block",
      color: "#ffffff",
      padding: "10px 0",
      fontWeight: "normal",
      textAlign: "center",
      height: "160px",
      width: "140px",
      lineHeight: "60px",
    },
    featureBackground: {
      display: "inline-block",
      background: "#001f18",
      borderRadius: "50%",
      padding: "10px",
    },
    featureBackgroundImage: {
      display: "inline-block",
      borderRadius: "50%",
      padding: "0px 5px",
    },
    featureImage: {
      margin: "0 auto !important",
      display: "inline-block",
      color: "#ffffff",
      fontWeight: "normal",
      borderRadius: "50%",
      height: "160px",
      width: "170px",
      padding: "10px",
      lineHeight: "60px",
    },
    featureText: {
      color: "#fff",
      fontWeight: "normal",
      textAlign: "center",
      verticalAlign: "middle",
      display: "block",
      width: "auto",
      margin: "0",
      lineHeight: "normal",
    },
    arrowLeft: {
      position: "absolute",
      top: "45%",
      width: "27px",
      height: "44px",
      marginTop: "-22px",
      zIndex: "10",
      cursor: "pointer",
    },
    arrowRight: {
      position: "absolute",
      top: "45%",
      width: "27px",
      right: "10px",
      height: "44px",
      marginTop: "-22px",
      zIndex: "10",
      cursor: "pointer",
    },
    featureIcon: {
      display: "block",
      margin: "0 auto",
      textAlign: "center",
      verticalAlign: "center",
    },
    footer: {
      background: "#FFF",
      color: "#000",
    },
    footerIcon: {
      maxHeight: "125px",
      padding: "10px",
      verticalAlign: "top",
    },
    footerInfo: {
      padding: "0px 20px",
      margin: "auto",
      //position: 'absolute',
      top: "50%",
      //transform: 'translate(0, -50%)'
    },
    footerNav: {
      textAlign: "right",
      padding: "10px 0px",
      // position: 'absolute',
      top: "50%",
      //transform: 'translate(0, -50%)'
      "@media (max-width: 768px)": {
        textAlign: "center",
      },
    },
    footerNavItem: {
      textDecoration: "none",
      color: "#FFF",
      padding: "0 10px",
      fontSize: "1em",
      letterSpacing: "1px",
      fontWeight: 600,
      "&:hover": {
        textDecoration: "none",
      },
    },
    footerSocialListItem: {
      display: "inline-block !important",
      padding: "10px 0px !important",
      position: "relative",
      width: "auto !important",
      height: "40px",
    },
    blogEllipsis: {
      /* hide text if it more than N lines  */
      overflow: "hidden",
      /* for set '...' in absolute position */
      position: "relative",
      /* use this value to count block height */
      lineHeight: "1.2em",
      /* max-height = line-height (1.2) * lines max number (3) */
      maxHeight: "3.45em",
      /* fix problem when last visible word doesn't adjoin right side  */
      textAlign: "justify",
      /* place for '...' */
      marginRight: "-1em",
      paddingRight: "1em",
      "&::before": {
        /* points in the end */
        content: "...",
        /* absolute position */
        position: "absolute",
        /* set position to right bottom corner of block */
        right: "0",
        bottom: "0",
        color: "FFF",
      },
      "::after": {
        /* points in the end */
        content: "...",
        /* absolute position */
        position: "absolute",
        /* set position to right bottom corner of text */
        right: "0",
        /* set width and height */
        width: "1em",
        height: "1em",
        marginTop: "0.2em",
        /* bg color = bg color under block */
        background: "white",
      },
    },
    contactsContainer: {
      padding: "0 0 72px",
      //borderTop: "2px solid #001f18",
      marginTop: "10px",
      "@media only screen and (max-width: 767px)": {
        borderWidth: "1px 0 0",
      },
    },
    tabsIndicator: {
      backgroundColor: "#003227",
    },
    contactHeaderRoot: {
      overflow: "hidden",
    },
    contactsEntry: {
      overflow: "hidden",
      position: "relative",
      //padding: "48px 30px 53px",
      padding: "48px 0px 53px",
      //borderBottom: "2px solid #001f18",
      "@media only screen and (max-width: 767px)": {
        borderWidth: "0 0 1px",
        textAlign: "left",
        //padding: '37px 9px 43px'
        padding: "37px 0px 43px",
      },
    },
    contactsImageSection: {
      //float: 'left',
      //margin: '4px 51px 0 0',
      margin: "0",
      "@media only screen and (max-width: 767px)": {
        textAlign: "center",
        margin: "0 0 25px",
        float: "none",
      },
    },
    contactsImageContainer: {
      //float: 'right',
      //margin: '0 0 0 43px',
      margin: "0",
      "@media only screen and (max-width: 950px)": {
        margin: "0 auto 15px",
        float: "none",
        display: "block",
      },
    },
    contactsImage: {
      //borderRadius: '50%',
      position: "relative",
      //verticalAlign: 'top',
      //boxShadow: '3px 3px 5px 6px #ccc'
    },
    contactsSocialContainer: {
      height: "265px",
      width: "25px",
      whiteSpace: "nowrap",
      float: "right",
      "@media only screen and (max-width: 950px)": {
        width: "100%",
        height: "auto",
        float: "none",
        textAlign: "center",
      },
    },
    contactsSocialList: {
      padding: "0",
      margin: "0",
      listStyle: "none",
      display: "inline-block",
      verticalAlign: "middle",
    },
    contactsSocialListItem: {
      margin: "17px 0 0",
      overflow: "hidden",
      ":first-child": {
        margin: "0",
      },
      "@media only screen and (max-width: 950px)": {
        float: "left",
        margin: "0 0 0 16px",
        ":first-child": {
          margin: "0",
        },
      },
    },
    contactsSocialLink: {
      color: "#222",
      display: "block",
      overflow: "hidden",
      width: "25px",
    },
    contactsTextSection: {
      overflow: "hidden",
      position: "relative",
      "@media only screen and (max-width: 767px)": {
        textAlign: "left",
        fontSize: "20px",
        lineHeight: "21px",
      },
    },
    contactsTextTitle: {
      textTransform: "uppercase",
      margin: "0 0 28px",
      fontSize: "27px",
      "@media only screen and (max-width: 767px)": {
        fontSize: "21px",
        lineHeight: "24px",
        margin: "0 0 22px",
      },
    },
    contactsTextContent: {
      margin: "0 0 23px",
      "@media only screen and (max-width: 767px)": {
        margin: "0 0 19px",
      },
    },
    breadcrumbHeader: {
      lineHeight: "1.5",
      fontWeight: 600,
      letterSpacing: "1px",
      fontSize: "32px",
      margin: "0",
      "@media (max-width: 767px)": {
        textAlign: "center",
      },
    },
    breadcrumb: {
      listStyleType: "none",
      position: "absolute",
      width: "auto",
      top: "50%",
      left: "auto",
      right: "15px",
      margin: "-10px 0 0 0",
      "@media (max-width: 767px)": {
        position: "relative",
        top: "0",
        margin: "20px 0 0",
        textAlign: "center",
      },
    },
    breadcrumbHome: {
      fontSize: "20px",
      lineHeight: "1.5",
    },
    imageBarItem: {
      textAlign: "center",
      verticalAlign: "middle",
      backgroundSize: "100% 100%",
      backgroundPosition: "center",
      minHeight: "350px",
      backgroundColor: "rgba(0,0,0,0.5)",
      transition: "all .15s linear",
      height: "270px",
      padding: "60px",
      "@media (max-width: 676px)": {
        backgroundSize: "cover",
      },
      "@media (max-width: 991px)": {
        minHeight: "270px",
        height: "250px",
      },
      "&:hover": {
        backgroundSize: "120% 120%",
        textDecoration: "none",
      },
    },
    imageBarLink: {
      minHeight: "200px",
      width: "100%",
      display: "block",
      color: "white",
      "&:hover": {
        color: "white",
        textDecoration: "none",
      },
      "@media (max-width: 991px)": {
        minHeight: "150px",
      },
    },
    imageBarOverlay: {
      zIndex: 2,
      height: "100%",
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      background: "rgba(0, 0, 0, 0.5)",
    },
    imageBarText: {
      paddingTop: "30%",
      fontSize: "2em",
      textTransform: "uppercase",
      fontWeight: 400,
      letterSpacing: "1px",
    },
    buttonLargeOutline: {
      lineHeight: "42px",
      border: "2px solid " + color.primary,
      color: color.primary,
      backgroundColor: "#FFF",
      fontWeight: 600,
      borderRadius: "3px",
      height: "46px",
      padding: "0 26px",
      "&:hover": {
        backgroundColor: color.primary,
        color: "white",
      },
    },
    smallButton: {
      lineHeight: "30px",
      backgroundColor: color.primary,
      color: "white",
      fontWeight: 600,
      borderRadius: "3px",
      height: "30px",
      padding: "0 26px",
      width: "100%",
      display: "block",
      textTransform: "uppercase",
      "@media (max-width: 613px)": {
        padding: "0 10px",
      },
      "&:hover": {
        backgroundColor: color.primary,
        cursor: "pointer",
        color: "white",
        textDecoration: "none",
      },
    },
    navButton: {
      color: "white",
      fontWeight: 600,
      width: "100%",
      padding: "20px !important",
      display: "block",
      "@media (max-width: 613px)": {
        padding: "0 10px",
      },
    },
    navButtonLink: {
      borderRadius: "3px",
      padding: "0 30px !important",
      margin: "20px",
      color: "white",
      height: "28px",
      fontSize: "0.8em",
      textTransform: "uppercase",
      backgroundColor: color.primary,
      "&:hover": {
        backgroundColor: color.primary,
        color: "white",
        textDecoration: "none",
      },
    },
    homepageButton: {
      lineHeight: "42px",
      backgroundColor: color.primary,
      color: "white !important",
      fontWeight: 600,
      borderRadius: "3px",
      height: "46px",
      padding: "0 26px",
      width: "100%",
      display: "block",
      "@media (max-width: 613px)": {
        lineHeight: "20px",
        padding: 0,
      },
      "&:hover": {
        backgroundColor: "#444",
        cursor: "pointer",
      },
    },
  });

/*const getTextColor = color => {
    let L = getColorLuminance(color)
    return (L > Math.sqrt(1.05 * 0.05) - 0.05) ? "#000000" : "#FFFFFF" 
};

const getColorLuminance = color => {
    color = String(color).replace(/[^0-9a-f]/gi, '');
	if (color.length < 6) {
		color = color[0]+color[0]+color[1]+color[1]+color[2]+color[2];
    }
};

const hexToRgb = hex => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};*/

export default webOne;
