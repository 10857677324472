import { useState, useEffect } from "react";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "raf-polyfill";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./layouts/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import "./tailwind.css";
import { createMuiTheme } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import ReactGA from "react-ga";
import SmartBanner from "react-smartbanner";
import "../node_modules/react-smartbanner/dist/main.css";
import Amplify from "aws-amplify";

export const App = () => {
  const [manifestData, setManifestData] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getManifestData = async () => {
      await fetch(
        process.env.REACT_APP_MANIFEST_URL
          ? process.env.REACT_APP_MANIFEST_URL
          : "https://cdn.myocv.com/ocvapps/a12722222/public/int_webManifest.json",
        { mode: "cors" }
      )
        .then((response) => response.json())
        .then((data) => setManifestData(data))
        .catch((err) => console.error(err));
    };

    getManifestData();
    setLoading(false);
  }, []);

  Amplify.configure({
    API: {
      endpoints: [
        {
          name: "ocvapps",
          endpoint:
            "https://oc1rhn99vi.execute-api.us-east-1.amazonaws.com/beta",
          region: "us-east-1",
        },
      ],
    },
  });

  const hist = createBrowserHistory();
  const googleAnalytics = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
  ReactGA.initialize(String(googleAnalytics));
  hist.listen((location1) => {
    ReactGA.pageview(location1.pathname);
    window.scrollTo(0, 0);
  });

  library.add(fab, far, fal, fad, fas);
  const theme = createMuiTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#222",
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
    },
    typography: {
      useNextVariants: true,
    },
  });

  if (manifestData === null || isLoading) {
    return (
      <img
        alt="Loading"
        src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif"
        className="loading-centered"
      />
    );
  }

  return (
    <>
      {manifestData?.data["manifest"][
        manifestData?.data["lookup"]["web"][
          process.env.REACT_APP_VERSION ?? ""
        ][1]
      ]["smartBanner"] && (
        <SmartBanner
          daysHidden={0}
          daysReminder={0}
          title={process.env.REACT_APP_TITLE}
        />
      )}
      <Router history={hist}>
        <MuiThemeProvider theme={theme}>
          <Switch>
            <Route
              key={"homeIndex"}
              path={"/"}
              component={(props: any) => (
                <Home {...props} data={manifestData} />
              )}
            />
            <Route key={"sitemap"} path={"/sitemap.txt"} />
          </Switch>
        </MuiThemeProvider>
      </Router>
      <Helmet>
        <title>{process.env.REACT_APP_TITLE}</title>
        <meta
          name={"description"}
          content={process.env.REACT_APP_DESCRIPTION}
        />
      </Helmet>
    </>
  );
};
